.c-frame-upper {
  position: absolute;
  left: 0;
  top: 20px;
  right: 20px;
  margin: auto;
  text-align: right;
  @include mobile {
    display: none;
  };

  &:before {
    content: "";
    height: 1px;
    background-color: #fff;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 380px;
    opacity: 0.2;
    margin: auto;
  }
}

.c-frame-upper__image {
  height: auto;
}

.c-frame-lower {
  width: 100%;
  position: relative;
  margin: 48px auto 0;
  padding-left: 20px;
  text-align: left;
  @include tablet {
    padding-left: 0;
  };
  @include mobile {
    padding: 0 16px;
    margin: 24px auto 0;
    box-sizing: border-box;
  };

  &:before {
    content: "";
    height: 1px;
    background-color: #fff;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 400px;
    opacity: 0.2;
    margin: auto;
    @include mobile {
      display: none;
    };
  }
}

.c-frame-lower__image {
  height: auto;
  @include mobile {
    width: 100%;
  };
}
