.p-top {
  background-color: $color-dark;
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  padding: 80px 0 20px;
  position: relative;
  @include tablet {
    padding: 80px 48px 20px;
  };
  @include mobile {
    padding: 24px 0 16px;
    height: auto;
    background-image: unset;
  };
}

a.p-top__company-link {
  @include spacemono;
  position: absolute;
  left: 20px;
  top: 48px;
  color: rgba(#fff, 0.6);
  font-size: $font-size-small;
  transition: color 0.25s;
  text-decoration: none;

  &:hover {
    color: $color-purple;
  }
  @include mobile {
    top: 12px;
    left: 12px;
  };
}

.p-top__side-b {
  position: fixed;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  @include mobile {
    position: absolute;
    right: 8px;
  };
}


.p-top__header {
  width: 100%;
  background-image: url("../images/design-ops-bg.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 120px 0;
  margin: 40px 0;
  @include mobile {
    background: none;
    padding: 40px 0;
    margin: auto;
  };

  .content-title {
    width: 100%;
    max-width: 430px;
    margin: auto;
    display: block;
    @include mobile {
      max-width: 80%;
    };
  }

  .content-image {
    display: none;
    @include mobile {
      width: 60%;
      margin: auto;
      display: block;
    };
  }
}

.p-top__intro {
  color: #fff;
  font-family: sans-serif;
  max-width: 960px;
  margin: auto;
  @include smooth-text;
  @include mobile {
    padding-right: 20px;
  };

  .inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    @include mobile {
      grid-template-columns: 1fr;
      padding: 0 16px;
      box-sizing: border-box;
    };
  }

  .title {
    @include spacemono;
    font-size: $font-size-medium;
    font-weight: bold;
    line-height: $line-height-default;
    margin: auto;
    @include mobile {
      padding: 0 16px;
    };
  }

  .details {
    font-size: $font-size-default;
    line-height: $line-height-default;
    @include mobile {
      font-size: $font-size-small;
      p {
        margin: auto;
      }
    };
  }

  .contact {
    font-size: $font-size-default;
    margin: auto;
    text-align: center;
    @include tablet {
      font-size: $font-size-small;
    };
    @include mobile {
      font-size: $font-size-small;
    };

    a {
      color: #fff;
      text-decoration: none;
      @include spacemono;
      @include link-hover;
    }
  }
}

.c-subscribe-button {
  display: block;
  background-color: $color-purple;
  color: $color-dark;
  font-size: $font-size-base;
  text-align: center;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  border-radius: 1px;
  transition: background-color 0.25s;
  &:hover {
    background-color: #fff;
  }
}
