.c-article-list {
  max-width: 960px;
  width: 100%;
  margin: 40px auto;
  font-family: sans-serif;
  list-style: none;
  padding: 0;
  @include mobile {
    padding: 0 16px;
    box-sizing: border-box;
  };
}

.c-article-list__item {
  border-bottom: 1px solid #fff;
  color: #fff;
  padding: 40px 0;
  @include mobile {
    padding: 20px 0;
  };
  &:first-child {
    padding-top: 0;
    @include mobile {
      padding-top: 0;
    };
  }
  &:last-child {

  }
  a {
    display: grid;
    grid-template-columns: 60px auto;
    grid-column-gap: 32px;
    color: #fff;
    text-decoration: none;
    @include link-hover;
    @include mobile {
      grid-template-columns: 48px auto;
      grid-column-gap: 16px;
    };
  }
  .vol {
    @include spacemono;
  }
  .counter {
    font-size: $font-size-xlarge;
    display: block;
    margin: auto;
    line-height: 1;
    @include mobile {
      font-size: $font-size-large;
    };
  }
  .count {
    font-weight: bold;
    display: block;
    font-size: $font-size-xxlarge;
    margin: auto;
    line-height: 1;
    @include mobile {
      font-size: $font-size-xlarge;
    };
  }
  .content {
    @include smooth-text;
    padding-top: 8px;
    position: relative;
    padding-right: 64px;
    @include mobile {
      padding-right: 32px;
    };
    .title {
      font-weight: normal;
      font-size: $font-size-large;
      line-height: 1.6;
      margin: auto;
      @include mobile {
        font-size: $font-size-base;
        line-height: 1.4;
        br {
          display: none;
        }
      };
    }
    .icon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 32px;
      margin: auto;
      img {
        height: 100%;
      }
      @include mobile {
        height: 24px;
      };
    }
  }
}
