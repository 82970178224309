@import "normalize.css";
/* Foundation */
@import "foundation/variables";
@import "foundation/mixins";
/* Layouts */
@import "layout/footer";
@import "layout/nav";
/* Components */
@import "objects/components/frame";
@import "objects/components/article_list";
/* Pages */
@import "objects/pages/top";

body {
  background-color: $color-purple;
}
