@import url('https://fonts.googleapis.com/css?family=Space+Mono:400,700&display=swap');
/* Font sizes */
$font-size-xxlarge: 3rem; // 48px
$font-size-xlarge: 2.25rem; // 36px
$font-size-large: 1.5rem; // 24px
$font-size-medium: 1.25rem; // 20px
$font-size-base: 1rem; // 16px
$font-size-default: 0.875rem; // 14px
$font-size-small: 0.75rem; // 12px
$font-size-smaller: 0.625rem; // 10px
/* Line Heights */
$line-height-default: 1.6em;
$line-height-compact: 1.3em; // Mixins
/* Colors */
$color-dark: #222222;
$color-purple: #958DFF;
$color-pink: #E0469A;
$color-teal: #8DF8FF;
$color-yellow: #FFBB56;
@mixin mobile {
  @media only screen and (max-width: 640px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
@mixin spacemono {
  font-family: 'Space Mono', monospace;
}

@mixin smooth-text {
  -webkit-font-smoothing: antialiased;
}

@mixin link-hover {
  transition: color 0.25s;
  &:hover {
    color: $color-purple;
  }
}
